<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<script>
import axios from "axios";
import dplayer from "@/components/dplayer";

export default {
  name: "App",
  components: {
    dplayer,
  },
  mounted() {},
  created() {
    this.dynamicList(1, 5);
  },
  // 响应数据
  data() {
    return {
      // 总条数
      total: 0,
      // 动态说说列表
      recordList: [],
      // 动态文本类型
      dynamicText: true,
      // 动态图片类型
      dynamicImage: true,
      // 动态音乐类型
      dynamicMusic: true,
      // 动态视频类型
      dynamicVideo: true,
    };
  },

  filters: {
     // 实时-时间-数据转换
     formatDatea(value) {
      console.log('获取到了',value)
      var date = new Date();
      console.log('date',date)

      // var year = value.getFullYear()
      // var month = formatNum(value.getMonth() + 1)
      // var day = formatNum(value.getDate())
      // var hour = formatNum(value.getHours())
      // var min = formatNum(value.getMinutes())
      // var sec = formatNum(value.getSeconds())
      return 6;
    },

      // vue日期格式化通用方法
    formatDate: function (value, args) {
      var dt = new Date(value);
      
      if (args == "yyyy-M-d") {
        // yyyy-M-d
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let date = dt.getDate();
        return `${year}-${month}-${date}`;
      } else if (args == "yyyy-M-d H:m:s") {
        // yyyy-M-d H:m:s
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let date = dt.getDate();
        let hour = dt.getHours();
        let minute = dt.getMinutes();
        let second = dt.getSeconds();
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
      } else if (args == "yyyy-MM-dd") {
        // yyyy-MM-dd
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2, "0");
        let date = dt.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${date}`;
      } 
      else {
        // yyyy-MM-dd HH:mm:ss
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2, "0");
        let date = dt.getDate().toString().padStart(2, "0");
        let hour = dt.getHours().toString().padStart(2, "0");
        let minute = dt.getMinutes().toString().padStart(2, "0");
        let second = dt.getSeconds().toString().padStart(2, "0");
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
      }
    },
  },
  methods: {
    //点击分页事件
    currentChange(current) {
      this.currentChangeIndex = current;
      this.dynamicList(current, 5);
    },

    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
    },

    switchVideo() {
      console.log("this.$refs=", this.$refs);
      this.$refs.dplayer.switchVideo(this.info);
    },
    dynamicList(current, size) {
      console.log("方法初始化...");
      var url = "http://114.132.200.51:8688/dynamic/listDynamic";
      var params = {
        current: current,
        size: size,
        //要么定义为params 传参{params}, 使用其他变量名,传参需{params:其他名}
      };
      // 传参数:只能解析params
      //注意1:传递的参数定义为params时,this.axios.get(url,{params})
      //注意2: 传递的参数定义为searchData时,this.axios.get(url,{params:searData})
      axios
        .get(url, { params })
        .then((res) => {
          // 动态列表数据
          this.recordList = res.data.data.recordList;
          // 总条数
          this.total = res.data.data.total;
          console.log("总条数:", this.total);
        })
        .catch((err) => {});
    },
  },
};
</script>

<style>
@import "./assets/css/style.css";

/**视频 */
#dplayer {
  max-width: 420px;
  height: 100%;
  border-radius: 10x;
}

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 380px;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-touch-callout: none;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-touch-callout: none;
}

div {
  display: block;
}

li {
  text-align: -webkit-match-parent;
}

body {
  font-size: 15px;
}

body {
  font-family: "HarmonyOS_Sans_SC_Medium";
  font-size: 18px;
  background-color: rgb(143 168 202 / 13%);
  -webkit-font-smoothing: antialiased;
  background-position: 50% 0;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
  color: rgba(34, 47, 62, 1);
  margin: 0 auto;
  padding: 0;
  width: 100%;
  line-height: 1.8;
}

.news-state {
  display: flex;
  align-items: center;
}

ol {
  list-style-type: decimal;
}

#dplayer {
  max-width: 400px;
  height: 100%;
  border-radius: 6px;
}

/** 图片圆角 */
.cross_photos dl img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  border-radius: 5px;
}

/**头像 */
.list_user_meta {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
}

.list_user_meta .avatar {
  width: 48px;
  height: 48px;
  min-width: 48px;
  margin-right: 10px;
}

.list_user_meta .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.list_user_meta .name {
  color: #576b95;
  display: flex;
  flex-direction: column;
}

.list_user_meta .name time {
  font-size: 13px;
  color: #aab0bf;
}
</style>